import * as React from "react"

// styles
const pageStyles = {
    color: "#232129",
    padding: 48,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 420,
}
const headingAccentStyles = {
    color: "#663399",
}
const paragraphStyles = {
    marginBottom: 48,
    fontFamily: 'monospace'
}

// data
const links = [
    {
        text: "Tutorial",
        url: "https://www.gatsbyjs.com/docs/tutorial/",
        description:
            "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: "#E95800",
    },
    {
        text: "How to Guides",
        url: "https://www.gatsbyjs.com/docs/how-to/",
        description:
            "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: "#1099A8",
    },
    {
        text: "Reference Guides",
        url: "https://www.gatsbyjs.com/docs/reference/",
        description:
            "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: "#BC027F",
    },
    {
        text: "Conceptual Guides",
        url: "https://www.gatsbyjs.com/docs/conceptual/",
        description:
            "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
        color: "#0D96F2",
    },
    {
        text: "Plugin Library",
        url: "https://www.gatsbyjs.com/plugins",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Build and Host",
        url: "https://www.gatsbyjs.com/cloud",
        badge: true,
        description:
            "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
        color: "#663399",
    },
]

// markup
const IndexPage = () => {
    return (
        <main style={pageStyles}>
            <title>Idealith</title>
            <h1 style={headingStyles}>
                idealith
                <br/>
                <span style={headingAccentStyles}>An audio driver for Mac that transcribe and summarize all your calls and meetings!</span>
                <span role="img" aria-label="Party popper emojis">
          🎙️📝
        </span>
            </h1>
            <p style={paragraphStyles}>
                <i>On-device processing, privacy-by-design. Launching Dec 2023</i>
            </p>
        </main>
    )
}

export default IndexPage
